<template>
  <TTableAdvance
    :items="list"
    :fields="fields"
    :store="store"
    resource="/customer/wallets"
    reloadable
    enterable
    noFilter
    @click-reload="fetch"
  >
    <template #id="{ item }">
      <td>
        <TMessage :content="item.id" />
      </td>
    </template>
    <template #wallet="{ item }">
      <td>
        <SMessageWallet :data="item" />
      </td>
    </template>
    <template #balance="{ item }">
      <td>
        <TMessageMoney :amount="item.balance" :currency="item.currency_id" />
      </td>
    </template>
    <template #amount_available_for_payment="{ item }">
      <td>
        <TMessageMoney
          :amount="item.amount_available_for_payment"
          :currency="item.currency_id"
        />
      </td>
    </template>
    <template #updated_at="{ item }">
      <td>
        <TMessageDateTime :content="item.updated_at" small />
      </td>
    </template>
  </TTableAdvance>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      store: "accounting.customer_wallets",
      fields: [
        { key: "_", _style: "width: 40px" },
        {
          key: "wallet",
          label: "Wallet",
          _style: "width: 300px; min-width: 300px",
          _classes: "text-wrap",
        },
        { key: "balance", label: "Balance", sorter: true },
        {
          key: "amount_available_for_payment",
          label: "Available payment",
          sorter: true,
        },
        {
          key: "updated_at",
          sorter: true,
          label: "Updated at",
          _style: "width: 250px",
        },
      ],
      filter: {},
      filter_between: {},
      dateRange: {
        start: null,
        end: null,
      },
    };
  },
  mounted() {
    this.getWallets();
    // if (this.store) {
    //   this.$store.dispatch(`${this.store}.fetch.if-first-time`);
    // }
  },
  computed: {
    list() {
      return this.$store.getters[`${this.store}.list`];
    },
    ...mapGetters({
      wallets: "accounting.customer_wallets.list",
    }),
  },
  methods: {
    fetch() {
      this.$store.dispatch(this.store + ".fetch");
    },
    getWallets() {
      if (this.wallets.length > 0) {
        this.$store.dispatch(`${this.store}.fetch.if-first-time`);
      } else {
        this.$store.dispatch(`${this.store}.push-query`, {
          "filter[walletable_id]": this.meID,
        });
      }
    },

    setUpdatedAtFilter({ start, end }) {
      this.filter_between["created_at"] = `${start},${end}`;
      this.filterChange();
    },
  },
};
</script>